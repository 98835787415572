<template lang="pug">
    button(@click="click ? click : () => null;") {{ text }}
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: () => 'action'
        },
        click: {
            type: Function
        }
    }
};
</script>

<style scoped>
button {
    position: relative;
    padding: var(--padding-small) var(--padding-base);
    outline: none;
    color: var(--text-color);
    background: #fff;
    border: 2px solid var(--text-color);
    border-radius: 6px;
    text-transform: uppercase;
    font-size: var(--font-size-tiny);
    font-weight: bold;
    cursor: pointer;

    -webkit-transition: all ease-in-out .2s;
    -moz-transition: all ease-in-out .2s;
    -o-transition: all ease-in-out .2s;
    transition: all ease-in-out .2s;
}

button:hover {
    color: #fff;
    background: var(--text-color);
    box-shadow: 0px 4px 8px rgba(64,64,64,.2);
}

button:active {
    box-shadow: none;
}
</style>