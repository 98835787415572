import Vue from 'vue';
import Router from 'vue-router';
import { isUserLoggedIn } from '@/services/user-service';

Vue.use(Router);

const authGuard = (to, from, next) => {
    if (isUserLoggedIn()) {
        next();
    } else {
        // Always go to /login if not logged in
        if (to.name != 'login') {
            next({ name: 'login' });
        }
    }
};

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            alias: [ '/index.html' ],
            beforeEnter: (to, from, next) => {
                if (to.name === 'home' && isUserLoggedIn()) {
                    next({ name: 'dashboard' });
                } else {
                    next();
                }
            },
            // eslint-disable-next-line
            component: () => import('@/pages/Landing.vue')
        },
        {
            path: '/login',
            name: 'login',
            // eslint-disable-next-line
            component: () => import('@/pages/Login.vue')
        },
        {
            path: '/signup',
            name: 'signup',
            // eslint-disable-next-line
            component: () => import('@/pages/Signup.vue')
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            beforeEnter: authGuard,
            // eslint-disable-next-line
            component: () => import('@/pages/Dashboard.vue')
        },
        {
            path: '/api-tokens',
            name: 'api-tokens',
            beforeEnter: authGuard,
            // eslint-disable-next-line
            component: () => import('@/pages/APITokens.vue')
        },
        {
            path: '/schema/:namespace/:schemaName',
            name: 'schema-details',
            beforeEnter: authGuard,
            // eslint-disable-next-line
            component: () => import('@/pages/SchemaDetails.vue')
        },
        { path: '/*', redirect: '/' }
    ]
});
