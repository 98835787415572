import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';

// Socket.io
import VueSocketIO from 'vue-socket.io';
Vue.use(new VueSocketIO({
    debug: process.env.NODE_ENV === 'development',
    connection: window.location.host
}));

Vue.config.productionTip = false;

// Modules
import router from './router';
Vue.use(VueAxios, axios);
axios.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (error.response.status === 401 && window.location.pathname != '/login') {
        window.location = '/login';
    } else {
        return Promise.reject(error);
    }
});

if (process.env.NODE_ENV === 'production') {
    require('./registerServiceWorker');
}

new Vue({
    router,
    render: h => h(App),
}).$mount('#app');
