import axios from 'axios';

const localStorageUserKey = 'asr-session';

export const login = async (username, password) => {
    const sessionData = (await axios.post('/api/v0/user/login', {
        username,
        password
    }))['data']['data'];

    _saveSession(sessionData);

    return sessionData;
};

export const getUserDetails = async () => {
    return (await axios.get('/api/v0/user', {
        headers: {
            'Authorization': `Bearer ${getToken()}`
        }
    }))['data']['data'];
};

export const signup = async (userData) => {
    const user = (await axios.post('/api/v0/user', userData))['data']['data'];
    return user;
};

export const logout = () => _deleteSession();
export const isUserLoggedIn = () => !!_getSession();

export const getToken = () => _getSessionAttribute('token');
export const getUsername = () => _getSessionAttribute('username');
const _getSessionAttribute = (attribute) => {
    try {
        const session = _getSession();
        return session[attribute];
    } catch (error) {
        return;
    }
};

const _saveSession = session => localStorage.setItem(localStorageUserKey, JSON.stringify(session));
const _getSession = () => JSON.parse(localStorage.getItem(localStorageUserKey));
const _deleteSession = () => localStorage.removeItem(localStorageUserKey);