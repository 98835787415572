<template lang="pug">
    .navbar
        .clearfix
            router-link.title.inline-block(:to="{ name: 'home' }")
                //- img.logo(:src="logo")
                | {{ isLanding ? 'SchemaDB' : 'Dashboard' }}

            .user.inline-block
                router-link(:to="{ name: 'api-tokens' }", v-if="!isLanding && isUserLoggedIn()", title="API Tokens")
                    i.icon.material-icons vpn_key
                //- i.icon.material-icons-outlined.inline-block(v-if="!isLanding") notifications

                router-link(:to="{ name: 'dashboard' }", v-if="isLanding && isUserLoggedIn()")
                    Button(text="enter")

                router-link(:to="{ name: 'login' }", v-if="isLanding && !isUserLoggedIn()")
                    Button(text="login")

                router-link(:to="{ name: 'login' }", v-if="!isLanding")
                    Button(text="logout")

</template>

<script>
import { isUserLoggedIn } from '@/services/user-service';
import Button from '@/components/Button';

export default {
    components: { Button },
    computed: {
        logo: () => require('@/assets/img/logo.png'),
        isLanding: function () {
            return this.$route.name === 'home';
        }
    },
    methods: {
        isUserLoggedIn: () => isUserLoggedIn()
    }
};
</script>

<style scoped>
.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--navbar-height);
    border-bottom: 1px solid #eaeaea;
    background: #fff;
}

.clearfix {
    position: relative;
    display: block;
    height: 100%;
    margin: 0 auto;
    max-width: var(--body-width);
}

.clearfix > .inline-block {
    vertical-align: middle;
    line-height: var(--navbar-height);
}

.navbar img.logo {
    margin-left: -4px;
    margin-right: var(--margin-small);
    height: calc(var(--navbar-height) / 2);
    vertical-align: middle;
}
.title {
    font-size: var(--font-size-small);
    font-weight: bold;
    text-decoration: none;
    outline: none;
}

.user {
    float: right;
    display: flex;
    height: 100%;
}
i.icon {
    margin: 0;
    margin-right: var(--margin-small);
    text-align: left;
    line-height: 60px;
    height: 60px;
    cursor: pointer;
    font-size: var(--font-size-icon);
    margin-right: 16px;
}
i.icon.material-icons {
    opacity: .8;
}
button {
    margin: auto;
}

.user a {
    position: relative;
    display: flex;
    text-decoration: none;
} 
</style>

<style scoped>
/* Responsive */

@media only screen and (max-width: 1200px) {
    .navbar {
        padding: 0 var(--padding-medium);
    }
}
</style>