<template lang="pug">
    #app(:class="{ pwa: isInStandaloneMode }")
        router-view
        Navbar
</template>

<script>

import Navbar from '@/components/Navbar.vue';

export default {
    name: 'app',
    components: { Navbar },
    computed: {
        isInStandaloneMode: () => ('standalone' in window.navigator) && (window.navigator.standalone)
    }
};
</script>

<style src="./styles.css"></style>
